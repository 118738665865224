import React, { useState } from 'react';
import './Home.scss';
import Layout from '../../Components/Layout/Layout.jsx';
import ProjectSlider from './Components/Project Slider/ProjectSlider.jsx';
import OurServices from './Components/OurServices/OurServices.jsx';
import SuccessCard from './Components/SuccessCard/SuccessCard.jsx';
import BenefitsCard from './Components/Benefits Card/BenefitsCard.jsx';
import BrandSlider from './Components/BrandSlider/BrandSlider.jsx';
import FramerCard from './Components/Framer Card/FramerCard.jsx';

export default function Home() {

  return (
    <Layout>
      <div className='home-container'>

        {/* ------ Power by Framer - Container ------ */}
        <div className='power-by-framer-container'>
          <div className='border-wrapper pt-2'>
            <div className='off-white-bg pt-3'>

              {/* ------ Header ------ */}
              <div className='side-padding pb-5'>
                <div className='header-container'>
                  <img src='/Assets/Logo/logo.png' height={80} alt='logo' />
                </div>
              </div>

              <div className='side-padding pt-5'>
                <div className='power-wrapper'>
                  <p className='title'>Elevating Your Brand with Innovative Design</p>
                  <p className='description px-md-5'>At Groovy Ads, we blend artistry and strategy to create designs that not only captivate but also elevate.</p>
                </div>
              </div>

              <div>
                <FramerCard />
              </div>

              <div className='mt-5'>
                <BrandSlider />
              </div>
            </div>
          </div>
        </div>

        {/* ------ Benefits - Container ------ */}
        <div className='benefits-container py-md-5'>
          <div className='side-padding my-5'>
            <div className='d-flex align-items-center justify-content-center mb-3'>
              <p className='title-tag m-0 px-4 py-1'>Benefits</p>
            </div>
            <div className='benefits-wrapper pb-2'>
              <p className='title'>Discover Why We Stand Out</p>
              <p className='description px-md-5'>Web design involves creating and designing websites, including layout, content, and user experience.</p>
            </div>
            <div className='mt-5'>
              <BenefitsCard />
            </div>
          </div>
        </div>

        {/* ------ Our services - Container ------ */}
        <div className='our-services-container py-5'>
          <div className='side-padding'>
            <div className='d-flex align-items-center justify-content-center mb-3'>
              <p className='title-tag m-0 px-4 py-1'>Our services</p>
            </div>
            <div className='service-title-wrapper mb-5'>
              <p className='title'>Expert Services That Define Our Excellence.</p>
              <p className='description px-md-5'>From creative design to technical solutions, our services define industry excellence.</p>
            </div>
            <div>
              <OurServices />
            </div>
          </div>
        </div>

        {/* ------ Navigating Success - Container ------ */}
        <div className='navigating-success-container py-5'>
          <div className='side-padding'>
            <div className='row m-0 px-0 py-md-5'>
              <div className='col-md-6 pr-md-5'>
                <div className='d-flex align-items-center'>
                  <p className='title-tag m-0 px-4 py-1'>Navigating Success Together</p>
                </div>
                <div className='mt-4 mr-md-3'>
                  <p className='title'>We lead you through every step</p>
                  <p className='description'>From creative design to technical solutions, our services define industry excellence.</p>
                </div>
              </div>
              <div className='col-md-6 pl-md-5'>
                <SuccessCard />
              </div>
            </div>
          </div>
        </div>

        {/* ------ Project Info - Container ------ */}
        <div className='project-info-container pt-5'>
          <div className='side-padding pt-md-5'>
            <div>
              <p className='title'>We lead you through every step</p>
              <p className='description pb-2'>From creative design to technical solutions, our services define industry excellence.</p>
              <div className='mt-4 mb-5 pb-5'>
                <a href='mailto:hello@groovyads.com'>
                  <button class='button button--winona button-green' data-text="hello@groovyads.com"><span>hello@groovyads.com</span></button>
                </a>
              </div>
            </div>
          </div>
          <div>
            <ProjectSlider />
          </div>
        </div>

        {/* ------ Schedule a Call - Container ------ */}
        <div className='call-container py-5'>
          <div className='side-padding my-5'>
            <div className='call-wrapper'>
              <p className='title'>We make your SaaS business shine</p>
              <p className='description'>Book a call to see how Perspective Design can help elevate your company's designs to the next level.</p>
              <div className='d-flex align-item-center justify-content-center mt-5'>
                <a href='mailto:hello@groovyads.com'>
                  <button className="button button--winona" data-text="hello@groovyads.com"><span>hello@groovyads.com</span></button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
