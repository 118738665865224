import React from 'react';
import './Footer.scss';
import './Footer.scss';

export default function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-top-belt py-2'>
        <marquee>
          <div className='d-flex align-items-center py-1'>
            <div className='d-flex align-items-center'><img src='/Assets/Images/flash-icon.png' className='mx-5' height={20} alt='logo' /><p className='m-0 title'>Web Design</p></div>
            <div className='d-flex align-items-center'><img src='/Assets/Images/flash-icon.png' className='mx-5' height={20} alt='logo' /><p className='m-0 title'>Branding</p></div>
            <div className='d-flex align-items-center'><img src='/Assets/Images/flash-icon.png' className='mx-5' height={20} alt='logo' /><p className='m-0 title'>Product design</p></div>
            <div className='d-flex align-items-center'><img src='/Assets/Images/flash-icon.png' className='mx-5' height={20} alt='logo' /><p className='m-0 title'>No-code development</p></div>
            <div className='d-flex align-items-center'><img src='/Assets/Images/flash-icon.png' className='mx-5' height={20} alt='logo' /><p className='m-0 title'>Digital marketing</p></div>
            <div className='d-flex align-items-center'><img src='/Assets/Images/flash-icon.png' className='mx-5' height={20} alt='logo' /><p className='m-0 title'>Framer Partner</p></div>
          </div>
        </marquee>
      </div>
      <div className='side-padding mb-5'>
        <div className='row m-0 p-0 my-5'>
          <div className='col-md-5'>
            <div className='d-flex align-items-center'>
              <img src='/Assets/Logo/logo-white.png' height={90} alt='logo' />
            </div>
            <p className='descriptions m-0 mt-4'>Your Ultimate Task Management Solution for Enhanced Productivity and Efficiency</p>
          </div>
        </div>
      </div>
      <div className='footer-bottom-belt mt-5'>
        <div className='side-padding'>
          <div className='d-flex justify-content-end'>
            <p className='my-2 tandc-line text-right'>© 2023 Groovy Ads. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div >
  )
}